import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Select, {components} from "react-select";
import {useLocation} from "react-router-dom";


const DealerListNew = ({data}) => {
console.log('shuvo',data)
    const [filterState, setFilterState] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [matchedDataset, setMatchedDataset] = useState([]);
    const uniqueDealerLocs = new Set(data.map((item) => item.dealer_loc));
    const dealerLocation = [
        {value: 'All', label: 'All'},
        ...Array.from(uniqueDealerLocs).map((loc) => ({
            value: loc,
            label: loc,
        })),
    ];
    useEffect(() => {
        const filteredData = data.filter(
            (item) =>
                (filterState === 'All' ||
                    item.dealer_loc.toLowerCase().includes(filterState.toLowerCase()) ||
                    item.post_title.toLowerCase().includes(filterState.toLowerCase())) &&
                (item.dealer_loc.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.post_title.toLowerCase().includes(searchValue.toLowerCase()))
        );
        setMatchedDataset(filteredData);
    }, [data, filterState, searchValue]);




    const handleFilter = (e) => {
        setFilterState(e);
    };

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: "#F5F5F5",
            borderRadius: 50,
            paddingLeft: 20,
            paddingRight: 20,
            boxShadow: state.isFocused ? null : null,

            "&:hover": {
                cursor: 'pointer'
            },
        }),
        option: (styles, state) => ({
            ...styles,
            // backgroundColor: state.isSelected ? 'rgb(50, 50, 50)' : '#FFFFFF',
            color: state.isSelected ? '#ffffff' : 'rgb(50, 50, 50)',
            "&:hover": {
                backgroundColor: "transparent",
                // color: '#ffffff',
                cursor: 'pointer'
            },
        }),
        indicatorContainer: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        })
    };


    const CaretDownIcon = () => {
        return <img src={'/images/static/indecator.svg'} style={{width: 20, height: 10}}/>;
    };


    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };


    return (

        <>
            {data?.[0] && (
                <>
                    <StyledDealerSearch className='dealer-list'>
                        <Container>
                            <Row>
                                <Col md={3} className={'filter'}>
                                    <Select
                                        styles={customStyles}
                                        onChange={oc => handleFilter(oc.value)}
                                        options={dealerLocation}
                                        components={{DropdownIndicator}}
                                        classNamePrefix={'react-select'}
                                        defaultValue={{label: 'Select District', value: 'Select District'}}
                                    />
                                </Col>
                                <Col md={{span: 3, offset: 6}} className={'filter-search'}>
                                    <form>
                                        <div className="search-input">
                                            <div className="global-image" height="15px" width="15px">
                                                <img src="/images/static/search.svg" alt="seach-icon"/>
                                            </div>
                                            <input
                                                type="text"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                                placeholder="Search"
                                                className="form-control"
                                            />
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </Container>
                    </StyledDealerSearch>

                    <StyledComponent className='about-partex pt-80 pb-200 parallax-img'>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>SL.</th>
                                            <th>District</th>
                                            <th>Thana</th>
                                            <th>Customer Name</th>
                                            <th>Detail Address</th>
                                            <th>Owner's name</th>
                                            <th>Contact Number</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {matchedDataset?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.dealer_loc}</td>
                                                <td>{item?.ph_code}</td>
                                                <td>{item?.post_title}</td>
                                                <td>{item?.address}</td>
                                                <td>{item?.proprietor_name}</td>
                                                <td>{item?.phone_number}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>
                    </StyledComponent>
                </>
            )}
        </>
    )
        ;
};

const StyledDealerSearch = styled.section`
  background: #323232;
  padding: 20px 0;

  .filter-search {
    cursor: pointer;
    position: relative;

    form {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: 1px solid #ffffff;
      border-radius: 50px;
      padding: 0 15px;
      z-index: 1;
      background-color: transparent;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .search-input {
        display: flex;
        align-items: center;
        height: 100%;

        .form-control {
          width: 100%;
          height: 100%;
          padding: 14px 0px 6px 0px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: #ffffff;
          background-color: transparent;
          border: none;
          border-radius: 0;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin: 0 0 0 30px;
        }
      }

      .global-image {
        position: absolute;
        height: 18px;
        width: 18px;
        margin: 0px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          inset: 0px;
        }
      }
    }

    @media (max-width: 767px) {
      height: 55px;
      margin-top: 30px;

      form {
        right: 15px;
        left: 15px;
        width: calc(100% - 30px);
      }
    }

  }

  .react-select {

    &__menu-list {
      padding: 0;
    }

    &__menu {
      border-radius: 0;
      z-index: 10;
    }

    &__control {
      padding: 12px 20px 8px 20px;
    }

    &__input-container {
      margin: 0;
    }

    &__single-value {
      color: #ffffff;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }

    &__indicator {
      //color: green;

    }

    &__option {
      background-color: #ffffff;
      border-radius: 0;
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;

      &:hover {
        background-color: rgb(50, 50, 50);
        color: #ffffff;
      }

      &--is-focused {
        //color: white;
        //background-color: black;
      }

      &--is-selected {
        font-weight: 500;
        background-color: rgba(50, 50, 50, 0.5);
      }
    }

    &__indicator-separator {
      background-color: transparent;
    }

  }

`;


const StyledComponent = styled.section`

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid rgba(50, 50, 50, 0.2);

      &:nth-last-child(1) {
        td {
          padding-bottom: 0;
          min-width: 30px;
        }
      }

      td {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        padding: 20px 30px 20px 0;

        &:nth-last-child(1) {
          padding-right: 0;
        }
      }
    }

    thead {
      tr {
        border-bottom: 2px solid #323232;

        th {
          font-weight: 500;
          padding: 0 30px 20px 0;

          &:nth-last-child(1) {
            padding-right: 0;
          }

          &:nth-child(1) {
            min-width: 70px;
          }

          &:nth-child(2) {
            min-width: 70px;
          }

          &:nth-child(3) {
            min-width: 70px;
          }

          &:nth-child(4) {
            min-width: 170px;
          }

          &:nth-child(5) {
            min-width: 270px;
          }

          &:nth-child(6) {
            min-width: 100px;
          }

          &:nth-child(7) {
            min-width: 170px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-last-child(1) {
          border-bottom: 0;

          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  @media(max-width: 767px){
    .col-sm-12 {
      overflow-x: scroll;
    }
  }

`;


export default DealerListNew;
