import {createGlobalStyle, css} from 'styled-components';
import {hoverr, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/FoundersGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/FoundersGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/FoundersGrotesk-Medium.woff2') format('woff2'),
    url('/fonts/FoundersGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/FoundersGrotesk-Light.woff2') format('woff2'),
    url('/fonts/FoundersGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/FoundersGrotesk-Regular.woff2') format('woff2'),
    url('/fonts/FoundersGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/fonts/FoundersGrotesk-Semibold.woff2') format('woff2'),
    url('/fonts/FoundersGrotesk-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: 'Founders Grotesk', 'Euclid Square', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
    
      scrollbar-width: none;
      -ms-overflow-style: none;


    .custom-scrollbars__content::-webkit-scrollbar {
      display: none;
    }
  }

  html {
    scrollbar-color: ${hoverr} ${text};
    scrollbar-width: thin;
  }

  .upper-title {
    font-weight: 500;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hoverr} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hoverr};
    color: #FFF;
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }


  //FOR H1

  h2 {
    font-family: 'Founders Grotesk';
    font-size: 80px;
    font-weight: 500;
    line-height: 80px;
    font-kerning: none;

  }

  h3 {
    font-family: 'Founders Grotesk';
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    font-kerning: none;

  }

  h4 {
    font-family: 'Founders Grotesk';
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    font-kerning: none;

  }

  p {
    font-family: 'Founders Grotesk';
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    font-kerning: none;

  }

  //RESPONSIVE
  @media (max-width: 767px) {
    h2 {
      font-size: 60px;
      font-weight: 500;
      line-height: 60px;
    }

    h3 {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 150px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 150px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }
  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;

    //@media (max-width: 767px) {
    //  padding-top: 80px;
    //}
  }

  .pb-120 {
    padding-bottom: 120px;

    //@media (max-width: 767px) {
    //  padding-bottom: 80px;
    //}
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }

  .pb-40 {
    padding-bottom: 40px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }
  
  .container-fluid{
    padding: 0;
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hoverr};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  //modal

  .modal {
    right: 0;
    left: unset;
  }

  .gph_modal {
    .modal-dialog {
      margin: 0;
      max-width: 100%;
    }

    .modal-body .row {
      flex-direction: column;
    }

    .modal-body {
      .legacy-right{
        height: fit-content;
        overflow: hidden;
        @media (max-width: 767px){
          .legacy-right{
            padding-top: 30px;
          }
        }
      }
      .legacy-left__image-wrapper {
        position: relative;
        padding-top: calc(430 / 430 * 100%);
      }
      .legacy-left__image-text{
        padding-top: 30px;
      }

      .for-close {
        padding: 24px 0 38px;
        align-items: flex-end;

        .modal-close {
          cursor: pointer;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          padding: 5px;

          svg {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
            margin-left: 20px;

            &:hover {
              cursor: pointer;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
              transform: rotate(90deg);
            }
          }

          &:after {
            display: none;
          }
        }
      }
      @media (max-width: 767px){
        .modal-data{
          flex-direction: column;
        }
      }
    }
  }

  /* Add this CSS for custom modal transition */
  .gph_modal {
    width: 90%;
    transform: translateX(100%);
    transition: all 800ms ease-in-out;
    opacity: 1!important;

    .legacy-left__image-wrapper img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    
    @media (max-width: 767px){
      width: 100%;
    }
    
  }

  .modal-backdrop {
    opacity: 0;
    transition: opacity 800ms ease-in;
  }

  .gph_modal.show {
    transform: translateX(0);
    transition: transform 800ms ease-in-out;

    //.legacy-right p {
    //  transform: translateY(0);
    //  transition: all 1s ease-in;
    //}
    }
    //.legacy-left__image-text{
    //  h5,p{
    //    transform: translateY(0);
    //    transition: all 1s ease-in;
    //  }
    //}
  

  .modal-backdrop.show {
    opacity: .8!important;
    transition: opacity 800ms ease-in;
  }
  .modal.fade .modal-dialog{
    transform: unset;
    transition: unset;
  }
  .modal.show .modal-dialog{
    transform: unset;
    transition: unset;
  }
  

  //modalpop for director page

  .director-modal {

    .legacy-left {
      position: sticky;
      top: 110px;
      height: 100%;
      padding-right: 70px;

      &__image-wrapper {
        position: relative;
        padding-top: calc(389 / 368 * 100%);
      }

      &__image-text {
        padding-top: 30px;

        h3 {
          color: black;
          font-size: 28px;
          line-height: 28px;
          font-weight: 500;
        }

        h5 {
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
          margin: 0 0 5px 0;
          transition: color .3s ease;
        }

        p {
          color: black;
          font-size: 20px;
          font-weight: 300;
          line-height: 24px;
        }
      }

      @media (max-width: 767px) {
        position: relative;
        top: 0px;
        height: 100%;
        padding-right: 15px;

      }
    }

    @media (max-width: 767px) {
      .modal-data {
        flex-direction: column;
      }
    }

    .legacy-right {
      position: relative;
      padding-top: 30px;

      p {
        color: black;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        padding-bottom: 30px;
      }

      &:before {
        content: "";
        position: absolute;
        height: 1px;
        background-color: black;
        top: 0;
        left: 15px;
        right: 15px;
      }

      ul {
        padding-bottom: 45px;

        li {
          position: relative;
          font-size: 20px;
          font-weight: 300;
          line-height: 24px;
          border-bottom: 1px solid #707070;
          padding-bottom: 15px;
          margin-left: 25px;

          :before {
            content: "";
            position: absolute;
            background-color: red;
            width: 12px;
            height: 12px;
            border-radius: 50px;
            left: -25px;
            margin-top: 2px;
          }
        }

        li:not(:first-child) {
          padding-top: 15px;
        }
      }
    }

    @media (max-width: 767px) {
      padding-bottom: unset;
      .legacy-left {
        &__image-text {
          margin-bottom: 30px;
        }
      }

    }
  }


  //modal popup for career page


  .cv-modal {
    padding-left: 0 !important;
    z-index: 99999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      height: 100vh;
      background: #F5F5F5;
      color: #323232;
      padding-bottom: 200px;
      @media (max-width: 767px) {
        height: fit-content;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 40px;
      }
    }

    .modal-data__content {
      position: relative;

      h3 {
        color: #E50019;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 60px;
        padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 26px;
          margin-bottom: 30px;
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #B2A89F;
        margin-bottom: 40px;
        color: rgb(50, 50, 50);
        padding-left: 0;

        &::placeholder {
          color: #323232;
        }
      }

      .form__phoneEmail {
        display: flex;
        gap: 20px;
        @media (max-width: 767px) {
          display: block;
          input {
            width: 100% !important;
          }
        }
      }

      input[type="textarea"] {
        padding-top: 10px;
        padding-bottom: 80px;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        @media (max-width: 767px) {
          padding-bottom: 50px;
        }
      }

      .button-group {
        display: flex;
        gap: 40px;
        margin-bottom: 10px;

        .attach-cv {
          background-color: #323232;
          width: 178px;
          cursor: pointer;
          border: 1px solid #fff;
          border-radius: 28px;
          height: 49px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          label {
            padding-top: 5px;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 0;
            cursor: pointer;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
        }

        .dc-btn a {
          padding: 26px 43px 22px;
        }
      }

      .file-name {
        padding-top: 5px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: #FFFDFB;
      }

      p {
        color: #FFFDFB;
        margin-bottom: 60px;
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
    }

    .for-close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
      margin-top: 40px;
      @keyframes fillAnimation {
        0% {
          r: 0;
        }
        100% {
          r: 34.5;
        }
      }
      @keyframes reverseFillAnimation {
        0% {
          r: 34.5;
        }
        100% {
          r: 0;
        }
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
        margin-top: 0;
        p {
          font-size: 18px;
        }

        svg {
          margin-left: 12px !important;
          width: 18px;
          height: 18px;
        }
      }
    }

    .modal-data {
      justify-content: unset;

      .pop-subtitle p {
        color: #323232;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
        @media (max-width: 767px) {
          font-size: 18px;
          padding-bottom: 10px;
        }
      }
    }
  }


  //product popup
  .product-popup {
    background-color: #FFFFFF;

    .legacy-left {
      padding-bottom: 50px;
      padding-left: 70px;
      padding-right: 70px;
      .product-border{
        border: 20px solid rgb(233 233 233);
      }

      &__image-wrapper {
        padding-top: calc(500 / 230 * 100%)!important;
      }
      @media (max-width: 991px){
        padding-left: 0;
        padding-right: 0;
      }
    }
    .legacy-right{
      position: relative;
      &:after{
        content:'';
        position: absolute;
        top: 0;
        left: 15px;
        height: 1px;
        width: calc(100% - 15px);
        background-color: #323232;
      }
     p{
       padding-top: 30px;
     }
    }
    .product__text {
      padding-top: 30px;
      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
      }

      h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
      }
    }
  }


  //get quotation

  .cta-pop-up {
    @media (max-width: 767px) {
      &.pt-200 {
        padding-top: 150px;
      }

      &.pb-200 {
        padding-bottom: 150px;
      }
    }

    .row {
      position: relative;
    }

    .bg-text-one, .bg-text-two {
      font-size: 240px;
      line-height: 240px;
      font-weight: 700;
      margin: 0;
      color: #E8E8E8;
      position: absolute;
      white-space: nowrap;


      @media (max-width: 767px) {
        font-size: 120px;
        line-height: 120px;
      }
    }

    .bg-text-one {
      left: 25%;
      top: 50px;

      @media (max-width: 767px) {
        bottom: 120px;
      }
    }
    .bg-text-two {
      left: -5%;
      top: 250px;
    }

    h4 {
      color: #E50019;
      margin-bottom: 80px;
      //padding-bottom: 10px;
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #323232;
      margin-bottom: 40px;
      color: #323232;
      padding-left: 0;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;

      &::placeholder {
        color: #323232;
      }
    }

    .form__phoneEmail {
      display: flex;
      gap: 20px;

      input {
        width: 49% !important;
      }

      @media (max-width: 767px) {
        gap: 0;
        flex-direction: column;
        width: 100%;

        input {
          width: 100% !important;
        }
      }
    }

    input[type="textarea"] {
      padding-top: 10px;
      padding-bottom: 80px;
    }

    .button-group {
      display: flex;
      gap: 40px;
      margin-bottom: 10px;

      .attach-cv {
        background-color: #323232;
        width: fit-content;
        padding: 0 35px;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 28px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        label {
          padding-top: 5px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: 0;
          cursor: pointer;
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .dc-btn {
        width: 162px;
        height: 49px;
      }
    }

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(22, 31, 19, 0.2);
      margin-bottom: 40px;
      //color: #161F13;
      padding-left: 0;

      &::placeholder {
        //color: #161F13;
      }

      &:focus {
        border-bottom: 1px solid rgba(22, 31, 19, 0.2);
      }
    }


    p {
      color: #323232;
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }

  //sticky menu

  //menu fixed

  .scroll-down .main-menu-desktop, .scroll-down .Mobile-menu-wrap {
    transform: translate3d(0, -105%, 0) !important;
    background:transparent;
    transition: all 0.5s ease-in-out;
    &logo {
      #desktop {
        display: block;
      }

      .desktopUp {
        display: none;
      }
    }

  }


  .scroll-up .main-menu-desktop, .scroll-up .Mobile-menu-wrap {
    transition-delay: 10s;
    background: #FFFFFF!important;
    transition: all 0.5s ease-in-out 0s;
    box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);

    .header {
      .white-logo {
        display: none;
      }
      .black-logo {
        display: block;
      }
      &__menu {
        li {
          a {
            color: black!important;
          }
        }
      }
    }

    &logo {
      #desktop {
        display: none;
      }

      .desktopUp {
        display: block;
      }
    }

    .header__right__logo {
      p {
        color: black !important;
      }

      .hamburger-icon span {
        background: #000 !important;
      }
    }

    .header__menu li a:hover {
      opacity: 0.7;
      color: black !important;
    }


    .logo {
      svg {
        g {
          #Path_1, #Path_2, #Path_3, #Path_9, #Path_10, #Path_11, #Path_12, #Path_13, #Path_14, #Path_15, #Path_16, #Path_17, #Path_18, #Path_19, #Path_20, #Path_21, #Path_22, #Path_23, #Path_24, #Path_25, #Path_26, #Path_27, #Path_28, #Path_29, #Path_30, #Path_31, #Path_32, #Path_33, #Path_34, #Path_35, #Path_36, #Path_37, #Path_38 {
            fill: black;
          }
        }
      }
    }


    //for mobile

    .mobile-menu .hamburger-icon {
      span {
        background: #000 !important;
      }
    }

  }

  .valid {
    color: ${hoverr};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //video modal

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  //toastify
  .Toastify {
    position: fixed;
    z-index: 999999999;
  }

  .Toastify {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hoverr};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    height: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  //text animation 
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  .opacity-30 {
    opacity: 30%;
  }

  .cursor-follower {
    position: fixed;
    background-size: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: translate(2px, 2px);
    visibility: hidden;
    //background-image: url('/images/static/left-arrow-circle.svg');

    &.left-btn {
      background-image: url('/images/static/left-arrow-circle.svg');
    }

    &.right-btn {
      background-image: url('/images/static/right-arrow-circle.svg');
    }

    &.visible-it {
      visibility: visible !important;
    }
  }


  ////custom scroll-bar
  //body {
  //  scrollbar-width: thin;
  //}
  ////
  //html {
  //  //overflow-y: scroll;
  //  -ms-overflow-style: none; /* IE and Edge */
  //  scrollbar-color: rgb(229, 0, 25) #F8F8F9; /* thumb color and track color */
  //  scrollbar-width: thin;
  //}
  ////
  ////
  //body::-webkit-scrollbar {
  //  width: 5px;
  //  background-color: #F8F8F9;
  //}
  ////
  //body::-webkit-scrollbar-thumb {
  //  background-color: #CA0034;
  //  transition: background-color 0.3s;
  //}
  //
  //body::-webkit-scrollbar-thumb:hover {
  //  background-color: #2B3944;
  //}
  
.key{
  #webpack-dev-server-client-overlay{
    display: none!important;
  }
}


  //page transition
  .page-transition {
    position: fixed;
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #323232;
    z-index: 999999999;
    display: none;
    opacity: 1;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 100px;
      opacity: 0;
    }
  }

  //for shaking 
  #smooth-content {
    will-change: transform;
  }

  .scroll-down .concern-nav{
    margin-top: 0px !important;
    transition: margin-top .6s ease;
  }
  .concern-nav{
    margin-top: 0px !important;
    transition: margin-top .6s ease;
  }

  .nav-bar .concern-nav{
    margin-top: 100px !important;
    @media (max-width: 991px) {
      margin-top: 0px !important;
    }
    transition: margin-top .6s ease;
  }
  
  .mobile-menu-open{
    overflow: hidden!important;
  }
  
  




`;



